@font-face {
  font-family: 'Jost';
  src: url(font/jost.ttf)
}

@font-face {
  font-family: 'Terminal';
  src: url(font/terminal.ttf)
}

@font-face {
  font-family: 'Hallelujah';
  src: url(font/GloriaHallelujah-Regular.ttf)
}

body {
  font-family: Terminal;
}

.terminal {
  font-family: Terminal;
}

.hallelujah {
  font-family: Hallelujah;
}

.jost {
  font-family: Jost;
}

.App {
  text-align: center;
  font-family: Terminal;
}

p {
  font-family: Terminal;
}

.test {
  width: 100wh;
  height: 100vh;
  color: #fff;
  /* background: linear-gradient(180deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 200% 200%;
  animation: Gradient 15s ease infinite; */
  }

.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes Gradient {
  0% {
    background-position: 100% 100%
  }
  50% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%
  }
}


@keyframes Clicked {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
} 


button {
  font-family: Jost;
  font-weight: 300;
  text-align: right;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


.blink {
  animation: blink-animation .1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
  display:inline;

}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}